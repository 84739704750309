'use client'

import { ReactNode, useCallback, useState, useMemo, useEffect, forwardRef, useRef } from 'react'
import { cls, isMobile, whisper } from '@/utils'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import { Creation, GalleryItemSource, PoNVoid } from '@/types'
import Loading from '@/components/loading'
import Empty from '../empty'
import Button from '../button'
import GalleryItem from './_item'
import GallaryImageItem from './_image-card'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { IntersectionGuard } from '@haiper/react-components'
import { useWindowSize } from 'usehooks-ts'
import Skeleton from '@/components/skeleton'
import useBrowser from '@/hooks/useBrowser'

export interface GalleryItem {
  id: string
  type: string
  thumbnailUrl?: string
  videoUrl?: string
}

export interface GalleryFilter {
  label: string
  value: string
}

export interface GalleryProps {
  className?: string
  items?: Creation[]
  source: GalleryItemSource
  loading?: boolean
  emptyDescription?: string | ReactNode
  emptyClassName?: string
  onClick?: (item: Creation) => PoNVoid
  filters?: GalleryFilter[]
  onFilter?: (filter: GalleryFilter) => PoNVoid
  defaultFilter?: GalleryFilter
  loadMore?: (page?: number) => PoNVoid
  hasMore?: boolean
  singleColumn?: boolean
}

const cacheMargin = 12

const Gallery = forwardRef<HTMLDivElement, GalleryProps>(
  (
    {
      singleColumn,
      className,
      items,
      loading,
      filters,
      loadMore,
      hasMore,
      onFilter,
      source,
      defaultFilter,
      emptyDescription,
      emptyClassName,
      onClick,
    }: GalleryProps,
    ref,
  ) => {
    const [activeFilter, setActiveFilter] = useState<GalleryFilter | null>(defaultFilter ?? filters?.[0] ?? null)
    const gridRef = useRef<HTMLDivElement>(null)

    const { isBelowMd } = useBreakpoint('md')
    const browser = useBrowser()

    useEffect(() => {
      if (defaultFilter) {
        setActiveFilter((old) => old ?? defaultFilter)
      }
    }, [defaultFilter])

    const { data: me, isValidating: profileLoading } = useCachedMyProfile()

    const handleFilterClick = useCallback(
      (newFilter: GalleryFilter) => {
        setActiveFilter(newFilter)
        onFilter?.(newFilter)
      },
      [onFilter],
    )

    const [hovingCreationId, setHovingCreationId] = useState<string | null>(null)

    const [deletedIds, setDeletedIds] = useState<string[]>([])
    const visibleItems = useMemo(() => {
      return items?.filter((item) => !deletedIds.includes(item.creation_id))
    }, [items, deletedIds])

    const firstItemRef = useRef<HTMLDivElement>(null)
    const [firstVisibleItem, otherVisibleItems] = useMemo(() => {
      return [visibleItems?.[0], [...(visibleItems ?? [])]?.slice(1)]
    }, [visibleItems])

    const handleDelete = useCallback((data: Creation) => {
      setDeletedIds((prev) => [...prev, data.creation_id])
    }, [])

    const checkLoadMore = useCallback(
      (creation: Creation) => {
        const index = items?.findIndex((item: Creation) => item.creation_id === creation.creation_id) ?? -1
        if (index > (items?.length ?? 0) - cacheMargin && hasMore !== false) {
          loadMore?.()
        }
      },
      [loadMore, items, hasMore],
    )

    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const [itemSize, setItemSize] = useState({ width: 0, height: 0 })

    const updateItemSize = useCallback(() => {
      const newSize = {
        width: firstItemRef.current?.offsetWidth ?? 0,
        height: firstItemRef.current?.offsetHeight ?? 0,
      }

      if (newSize.width !== itemSize.width || newSize.height !== itemSize.height) {
        setItemSize(newSize)
      }
    }, [itemSize])

    useEffect(() => {
      requestAnimationFrame(updateItemSize)
    }, [singleColumn, windowWidth, windowHeight, updateItemSize])

    // whisper('itemSize is: ', `${itemSize.width} x ${itemSize.height}`)

    const handleItemShow = useCallback(
      (creation: Creation) => {
        // whisper('gridRef is: ', gridRef.current)
        checkLoadMore(creation)
        requestAnimationFrame(updateItemSize)
      },
      [checkLoadMore, updateItemSize],
    )

    const renderContent = () => {
      if (profileLoading || (loading && !items?.length)) {
        return (
          <div
            className={cls('flex size-full justify-center items-center fixed inset-0 pointer-events-none', className)}
          >
            <Loading />
          </div>
        )
      }

      if (visibleItems?.length === 0 || !firstVisibleItem) {
        return (
          <div className={cls('flex pt-[150px] md:pt-0 size-full justify-center items-center', className)}>
            <Empty description={emptyDescription} className={emptyClassName} />
          </div>
        )
      }

      const getVariant = () => {
        if (isMobile()) {
          // return 'transparent'
          return 'outline'
        }
        if (singleColumn) {
          return 'outline'
        }
        return 'transparent'
      }

      return (
        <div
          ref={gridRef}
          className={cls(
            'grid grid-cols-1 gap-8 md:gap-6',
            singleColumn ? 'md:gap-8' : 'tablet:grid-cols-2 desktop:grid-cols-3',
            className,
          )}
          data-testid='creations-gallery-content'
        >
          {firstVisibleItem.output_type === 'image' ? (
            <GallaryImageItem
              key={firstVisibleItem.creation_id}
              source={source}
              creation={firstVisibleItem}
              userId={me?.user_id ?? null}
              className={cls('', singleColumn ? 'border-none border-0' : '')}
              size={singleColumn && !isBelowMd ? 'md' : 'sm'}
              singleColumn={singleColumn}
              variant={getVariant()}
              onClick={onClick}
              onShow={checkLoadMore}
              onDelete={handleDelete}
            />
          ) : (
            <GalleryItem
              ref={firstItemRef}
              key={firstVisibleItem.creation_id}
              source={source}
              creation={firstVisibleItem}
              userId={me?.user_id ?? null}
              singleColumn={singleColumn}
              className={cls('', singleColumn ? 'border-none border-0 p-0' : '')}
              variant={getVariant()}
              size={singleColumn && !isBelowMd ? 'md' : 'sm'}
              onClick={onClick}
              onShow={handleItemShow}
              onDelete={handleDelete}
            />
          )}
          {otherVisibleItems?.map((creation, index) =>
            creation.output_type === 'image' ? (
              <GallaryImageItem
                key={creation.creation_id + index}
                source={source}
                creation={creation}
                userId={me?.user_id ?? null}
                className={cls('', singleColumn ? 'border-none border-0' : '')}
                size={singleColumn && !isBelowMd ? 'md' : 'sm'}
                singleColumn={singleColumn}
                variant={getVariant()}
                onClick={onClick}
                onShow={checkLoadMore}
                onDelete={handleDelete}
              />
            ) : (
              <IntersectionGuard
                key={`guard-${creation.creation_id}`}
                width={itemSize.width || undefined}
                height={itemSize.height || undefined}
                containerProps={{
                  className: cls(
                    'relative',
                    browser?.name !== 'safari' ? (hovingCreationId === creation.creation_id ? 'z-[1]' : 'z-0') : '',
                  ),
                  onMouseEnter: () => setHovingCreationId(creation.creation_id),
                  onMouseLeave: () => setHovingCreationId((old) => (old === creation.creation_id ? null : old)),
                }}
                fallback={
                  <div className='size-full rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover active:border-border-hover p-2'>
                    <Skeleton variant='creation' className='size-full' />
                  </div>
                }
              >
                {() => (
                  <GalleryItem
                    key={creation.creation_id}
                    source={source}
                    creation={creation}
                    userId={me?.user_id ?? null}
                    singleColumn={singleColumn}
                    className={cls('', singleColumn ? 'border-none border-0 p-0' : '')}
                    variant={getVariant()}
                    size={singleColumn && !isBelowMd ? 'md' : 'sm'}
                    onClick={onClick}
                    onShow={handleItemShow}
                    onDelete={handleDelete}
                  />
                )}
              </IntersectionGuard>
            ),
          )}
        </div>
      )
    }

    return (
      <div ref={ref} className={cls('flex flex-1 size-full flex-col h-max')} data-testid='creations-gallery'>
        {filters && filters?.length > 0 ? (
          <ScrollArea className='whitespace-nowrap rounded-md md:rounded-[16px]'>
            <div className='flex mb-4 gap-4'>
              {filters.map((filter, index) => {
                const active = activeFilter === filter
                return (
                  <Button
                    key={[filter, index].join()}
                    className={cls(
                      'rounded-[20px] px-4 py-1 bg-surface hover:bg-surface-hover active:bg-surface-hover border-2 border-b-4 text-body-lg',
                      active ? 'text-text-interactive border-border-active hover:bg-surface' : '',
                    )}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter.label}
                  </Button>
                )
              })}
            </div>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        ) : null}
        {renderContent()}
      </div>
    )
  },
)

Gallery.displayName = 'Gallery'
export default Gallery
